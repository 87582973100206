<template>
    <v-dialog
        v-model="commentDialog"
        hide-overlay
        max-width="250px"
        persistent
        width="250px"
    >
        <v-form ref="commentsForm" lazy-validation>
            <v-card>
                <v-card-title class="px-4">{{ $t('message.addComments') }}</v-card-title>
                <v-card-text class="px-4">
                    <v-lazy>
                        <v-textarea
                            :rules="[...validationRules.required]"
                            auto-grow
                            clearable
                            dense
                            hide-details="auto"
                            ref="commentTextArea"
                            rows="1"
                            solo
                            v-model="productComment"
                        />
                    </v-lazy>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-2" @click="closeDialog" small>{{ $t('message.cancel') }}</v-btn>
                    <v-btn @click="addComment" :loading="loading.add" small color="info">{{ currentComment ? $t('message.save') : $t('message.add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import {api} from "Api";
import {validationRules} from "@/store/modules/appic/constants";

export default {
    name: 'AddProductComments',
    props: ['productId','openDialog','currentComment'],
    data(){
        return {
            dialog: false,
            loading: {
                add: false
            },
            productComment: null
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        commentDialog: {
            get() {
                return this.dialog;
            },
            set(value){
                this.dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addComment() {
            this.$refs.commentTextArea.blur()
            this.$nextTick(() => {
                if(this.$refs.commentsForm.validate()) {
                    this.loading.add = true
                }
            })
            api
                .put('/products/' + this.productId, {
                    product: {
                        comments: this.productComment
                    }
                })
                .then((response) => {
                    if (response.data.status == 'success') {
                        this.$toast.success(this.$t('message.successes.commentsAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.$emit('comment-added')
                    } else {
                        this.$toast.error(this.$t('message.errors.commentsNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.add = false
                    this.closeDialog()
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.commentsNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.add = false
                })
        },
        closeDialog(){
            this.$refs.commentsForm.reset()
            this.commentDialog = false
            this.productComment = null
        }
    },
    watch: {
        openDialog(value) {
            if(value){
                if(this.currentComment && this.currentComment.length > 0) this.productComment = this.currentComment
            }
            this.dialog = value
        }
    },
}

</script>

<style scoped>

</style>